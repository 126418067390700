<template>
  <b-card text-variant="opalean-gray-dark" class="card-custom gutter-b w-100 h-100">
    <b-card-text class="flex-column align-items-center h-100">
      <div class="table-container" style="overflow: auto; height: 42vh">
        <table class="table-custom table-striped table-hover">
          <thead>
            <tr>
              <th scope="col" style="width: 1%; padding: 8px; color: transparent">0</th>
              <th v-for="(header, index) in headers" :key="index" :id="'header-' + index" :style="getHeaderClass(header)" style="padding: 8px; resize: none">
                {{ header }}{{ MandatoryColumns.has(header) ? "*" : "" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="rows.length > 0">
              <tr v-for="(row, rowIndex) in rows" :key="rowIndex" :style="getRowClass(rowIndex)" ref="bodyRows">
                <th scope="row" style="padding: 8px; text-align: center">{{ rowIndex + 1 }}</th>
                <td v-for="(_, columnIndex) in row" :key="columnIndex" :style="getCellClass(rowIndex, headers[columnIndex])">
                  <textarea
                    :value="rows[rowIndex][columnIndex]"
                    @input="debouncedUpdate($event, rowIndex, headers[columnIndex])"
                    style="padding: 8px; resize: none; word-break: break-word"
                  ></textarea>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 38vh" v-translate>
                  No data found
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-card-text>
    <template v-slot:footer>
      <div class="d-flex align-items-stretch w-100">
        <div class="flex-grow-1 d-flex flex-column">
          <div class="d-flex flex-row align-items-start">
            <button class="d-flex align-items-center justify-content-center error-button" :class="{ active: processErrors.length > 0 }">
              <b-icon-exclamation-circle-fill class="text-danger h3 m-2"></b-icon-exclamation-circle-fill>
              <span class="font-size-h6 text-center mr-2"> {{ processErrors.length }} <translate>Errors</translate></span>
            </button>
            <div class="separator-custom mx-4"></div>
            <div class="ml-auto d-flex flex-row align-items-center">
              <button class="d-flex align-items-center justify-content-center action-button action-button-cancel" @click="cancelUpload" style="width: 125px">
                <b-icon icon="x-circle-fill" class="h3 m-2" style="color: #dc3545"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>Cancel</span>
              </button>
              <div class="ml-4"></div>
              <button
                v-if="rows.length >= 0 && processErrors.length === 0"
                class="d-flex align-items-center justify-content-center action-button action-button-upload"
                @click="uploadData()"
                style="width: 125px"
              >
                <b-icon icon="cloud-upload" class="h3 m-2" style="color: #28a745"></b-icon>
                <span class="font-size-h6 text-center mr-2" v-translate>Upload</span>
              </button>
            </div>
          </div>
          <div class="mb-4"></div>
          <div class="table-container" style="overflow: auto; height: 14vh">
            <table class="table-custom table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col" style="width: 1%; padding: 8px; text-align: center">
                    <b-icon-info-circle-fill class="text-muted"></b-icon-info-circle-fill>
                  </th>
                  <th scope="col" style="width: 15%; padding: 8px"><translate>Error Type</translate></th>
                  <th scope="col" style="padding: 8px"><translate>Description</translate></th>
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Line</translate></th>
                  <th scope="col" style="width: 10%; padding: 8px"><translate>Column</translate></th>
                </tr>
              </thead>
              <tbody>
                <template v-if="processErrors.length > 0">
                  <tr v-for="(error, index) in processErrors" :key="index">
                    <td style="padding: 8px; text-align: center">
                      <b-icon-exclamation-circle-fill class="text-danger"></b-icon-exclamation-circle-fill>
                    </td>
                    <td style="padding: 8px">
                      <span v-translate>Correctable Error</span>
                    </td>
                    <td style="padding: 8px">{{ error.description }}</td>
                    <td style="padding: 8px">{{ error.rowIndex + 1 }}</td>
                    <td style="padding: 8px">{{ error.column }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="100%" class="text-center" style="color: dimgrey; font-style: italic; background-color: white; height: 10vh" v-translate>
                      No errors found
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  //#region Data Management
  data() {
    return {
      /** @type {Array<{rowIndex: number, column: string, description: string}>}*/
      processErrors: [],
      Status: {
        ERROR: "error",
        SUCCESS: "success",
      },
      MandatoryColumns: new Set(["PartnerRef", "Name", "City", "CountryISO"]),
      OptionalColumns: new Set(["MainRole", "GroupA", "GroupB", "GroupR", "Adress1", "Adress2", "ZipCode", "City", "Phone", "Email", "Longitude", "Latitude"]),
      MainRoles: new Set(["CH", "DE", "TR"]),
      isLessThanOrEqual20Charaters: new Set(["PartnerRef", "GroupA", "GroupB", "GroupR", "ZipCode", "Phone"]),
      isLessThanOrEqual80Charaters: new Set(["Name", "Adress1", "Adress2", "City"]),
      isLessThanOrEqual200Charaters: new Set(["Email"]),
      updateTimeout: null,
      validCountryISOs: new Set(),
      partnerReferences: new Set(),
      /** @type {string[][]} */
      rows: [],
      /** @type {string[]} */
      headers: [],
    };
  },
  //#endregion

  props: {
    selectedFile: {
      type: String,
      required: true,
    },
  },

  watch: {
    selectedFile: {
      handler(csvContent) {
        this.parseCSVContent(csvContent);
      },
      immediate: true,
    },
  },

  mounted() {},

  computed: {
    ...mapGetters(["getCountries", "getPartners"]),
  },

  methods: {
    //#region CSV Parsing
    /** @param {string} csvContent */
    parseCSVContent(csvContent) {
      // Divise le contenu CSV en lignes individuelles
      const normalizedContent = csvContent.replace(/\r\n/g, "\n").replace(/\r/g, "\n");
      const lines = normalizedContent.split("\n").filter((line) => line.trim().length > 0);

      // Sépare la première ligne comme en-tête et le reste comme lignes de données
      const [headerLine, ...dataLines] = lines;

      // Divise la ligne d'en-tête en en-têtes individuels
      this.headers = headerLine.split(",");

      // Traite les lignes de données et supprime les lignes vides
      /** @type {string[][]} */
      this.rows = dataLines.filter((line) => line.trim().length > 0).map((line) => line.split(",").map((cell) => cell));

      this.checkHeaderValidity();

      // Appelle une méthode pour traiter davantage les lignes et les colonnes
      this.processRows();
    },
    //#endregion

    //#region Header Validation
    checkHeaderValidity() {
      const statusError = this.Status.ERROR;
      // Vérification des colonnes obligatoires
      const allMandatoryPresent = Array.from(this.MandatoryColumns).every((mandatoryColumn) => this.headers.includes(mandatoryColumn));
      if (!allMandatoryPresent) {
        return this.finishProcess(statusError, "Some mandatory columns are missing.");
      }

      // Vérification des colonnes en double
      const uniqueHeaders = new Set(this.headers);
      if (uniqueHeaders.size !== this.headers.length) {
        return this.finishProcess(statusError, "Duplicate columns detected in the CSV file.");
      }

      // Vérification de la validité de tous les en-têtes
      const allHeaderValid = this.headers.every((header) => this.MandatoryColumns.has(header) || this.OptionalColumns.has(header));
      if (!allHeaderValid) {
        return this.finishProcess(statusError, "Some headers are neither mandatory nor optional.");
      }

      // Vérification de la présence simultanée ou de l'absence des colonnes de coordonnées
      const hasLatitude = this.headers.includes("Latitude");
      const hasLongitude = this.headers.includes("Longitude");
      if (hasLatitude !== hasLongitude) {
        return this.finishProcess(statusError, "Latitude and longitude must be present or absent.");
      }
    },
    //#endregion

    //#region Data Upload
    uploadData() {
      const partners = this.rows.map((row) => this.createPartner(row));
      (async () => {
        try {
          await ApiService.post("/partner/import", partners);
          this.finishProcess(this.Status.SUCCESS, this.$gettext("Partners imported successfully"));
        } catch (error) {
          this.handleUploadError(error);
        }
      })();
    },
    createPartner(row) {
      const partner = {
        Partner: {
          Attributes: ["isActive"],
          CountryISO: row[this.headers.indexOf("CountryISO")] || "",
          Name: row[this.headers.indexOf("Name")] || "",
          ZipCode: row[this.headers.indexOf("ZipCode")] || "",
          City: row[this.headers.indexOf("City")] || "",
          MainRole: row[this.headers.indexOf("MainRole")] || "",
          Reference: row[this.headers.indexOf("PartnerRef")] || "",
        },
        PartnerDetails: {
          Address1: row[this.headers.indexOf("Adress1")] || "",
          Address2: row[this.headers.indexOf("Adress2")] || "",
          EMail: row[this.headers.indexOf("Email")] || "",
          Phone: row[this.headers.indexOf("Phone")] || "",
          GroupA: row[this.headers.indexOf("GroupA")] || "",
          GroupB: row[this.headers.indexOf("GroupB")] || "",
          GroupR: row[this.headers.indexOf("GroupR")] || "",
        },
      };
      if (row[this.headers.indexOf("Latitude")]?.length > 0) {
        partner["PartnerDetails"]["Latitude"] = row[this.headers.indexOf("Latitude")];
        partner["PartnerDetails"]["Longitude"] = row[this.headers.indexOf("Longitude")];
      }
      return partner;
    },
    //#endregion

    /** @param {Error} error Handles errors that occur during data upload: The error object from the upload attempt. */
    handleUploadError(error) {
      if (error.response && error.response.status === 500) return;

      let errorMessage = this.$gettext("Failed to upload data:");

      if (error.response?.data) {
        if (Array.isArray(error.response.data.Errors)) {
          error.response.data.Errors.forEach((err) => {
            errorMessage += `\n${err.OperationRef}: ${err.Error}`;
          });
        } else {
          errorMessage += ` ${error.response.data}.`;
        }
      } else {
        errorMessage += ` ${error.message}.`;
      }
      this.finishProcess(this.Status.ERROR, errorMessage);
    },
    finishProcess(status, message) {
      this.cancelUpload();

      // Shows a brief notification message.
      Swal.mixin({
        toast: true,
        icon: status,
        title: message,
        position: "top-end",
        showConfirmButton: false,
        timer: 10000,
      }).fire();
    },
    cancelUpload() {
      this.headers = [];
      this.rows = [];
      this.processErrors = [];
      this.$emit("fileDropped", false);
    },

    //#region Dynamic Styling
    getRowClass(rowIndex) {
      return this.processErrors.find((error) => error.rowIndex === rowIndex) ? "background-color: #ffcccc;" : "";
    },
    getHeaderClass(column) {
      return this.processErrors.find((error) => error.column === column) ? "background-color: #ffcccc;" : "";
    },
    getCellClass(rowIndex, column) {
      return this.processErrors.find((error) => error.column === column && error.rowIndex === rowIndex)
        ? "background-color: #f8d7da; border: 2px solid #e74a3b;"
        : "";
    },
    //#endregion

    //#region Data Processing
    processRows() {
      // Remplir le Set avec les codes ISO2
      this.getCountries.forEach((country) => {
        this.validCountryISOs.add(country.ISO2);
      });

      // Remplir le Set avec les références des partenaires
      this.getPartners.forEach((partner) => {
        this.partnerReferences.add(partner.Reference);
      });

      const headers = this.headers; // Référence locale pour éviter les accès répétés
      const headerSet = new Set(headers); // Créer un Set pour des recherches rapides si nécessaire

      this.rows.forEach((row, rowIndex) => {
        row.forEach((data, columnIndex) => {
          const header = headers[columnIndex]; // Utiliser la variable locale
          if (headerSet.has(header)) {
            // Vérification rapide si nécessaire
            this.updateCell(rowIndex, header, data);
          }
        });
      });
      return;
    },
    /**
     * @param {number} rowIndex
     * @param {string} column
     * @param {string} data
     */
    updateCell(rowIndex, column, data) {
      // Supprimer toutes les erreurs existantes pour cette cellule spécifique
      this.processErrors = this.processErrors.filter((error) => !(error.column === column && error.rowIndex === rowIndex));

      const errors = []; // Tableau temporaire pour stocker les erreurs

      // Vérification des caractères spéciaux
      const specialChars = /[!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?]+/;
      if (specialChars.test(data)) {
        this.processErrors.push({
          rowIndex: rowIndex,
          column: column,
          description: `Special characters are not allowed.`,
        });
      }

      const isNotValidMandatory = this.MandatoryColumns.has(column) && data.length === 0;
      if (isNotValidMandatory) {
        this.processErrors.push({
          rowIndex: rowIndex,
          column: column,
          description: `Mandatory field "${column}" is empty.`,
        });
      }

      //#region Vérification de la taille des données par colonne
      const lengthChecks = {
        20: this.isLessThanOrEqual20Charaters,
        80: this.isLessThanOrEqual80Charaters,
        200: this.isLessThanOrEqual200Charaters,
      };

      for (const [maxLength, columns] of Object.entries(lengthChecks)) {
        if (columns.has(column) && data.length > maxLength) {
          errors.push(`Field "${column}" needs to be less than or equal to ${maxLength} characters.`);
        }
      }
      //#endregion

      switch (column) {
        case "PartnerRef":
          if (this.partnerReferences.has(data)) {
            errors.push(`PartnerRef : "${data}" already exists in the system.`);
          }
          const isDoublePartnerRef = this.rows.some((row, index) => index !== rowIndex && row[this.headers.indexOf("PartnerRef")] === data);
          if (isDoublePartnerRef) {
            errors.push(`PartnerRef : "${data}" is duplicate in the file.`);
          }
          break;

        case "MainRole":
          if (!this.MainRoles.has(data)) {
            errors.push(`Field "${data}" is different from 'CH', 'DE' or 'TR'.`);
          }
          break;

        case "CountryISO":
          if (data && !this.validCountryISOs.has(data)) {
            errors.push(`Field "${data}" is not a valid country ISO.`);
          }
          break;

        case "Longitude":
        case "Latitude":
          const coordinateRegex = /^-?\d+(\.\d{1,9})?$/;
          const value = parseFloat(data);
          const isValidFormat = data.length === 0 || coordinateRegex.test(data);
          const rangeOfLatitude = 90;
          const rangeOfLongitude = 180;
          const actualRange = column === "Latitude" ? rangeOfLatitude : rangeOfLongitude;
          const isInRange = value >= -actualRange && value <= actualRange;

          if (data.length > 0 && (!isValidFormat || !isInRange)) {
            errors.push(`Field "${column}" must be between -${actualRange} and ${actualRange} coordinate.`);
          }

          // Vérification de la présence de l'autre coordonnée
          const otherColumn = column === "Longitude" ? "Latitude" : "Longitude";
          const otherValue = this.rows[rowIndex][this.headers.indexOf(otherColumn)];

          if (data.length === 0 && otherValue.length > 0) {
            errors.push(`Field "${column}" is missing.`);
          } else if (
            data.length > 0 &&
            otherValue.length === 0 &&
            !this.processErrors.some((error) => error.column === otherColumn && error.rowIndex === rowIndex)
          ) {
            this.processErrors.push({
              rowIndex: rowIndex,
              column: otherColumn,
              description: `Field ${otherColumn} is missing.`,
            });
          } else if (data.length === 0 && otherValue.length === 0) {
            this.processErrors = this.processErrors.filter((error) => !(error.column === otherColumn && error.rowIndex === rowIndex));
          }
          break;

        default:
          break;
      }

      // Ajouter toutes les erreurs collectées à processErrors
      for (const error of errors) {
        this.processErrors.push({
          rowIndex: rowIndex,
          column: column,
          description: error,
        });
      }
    },
    debouncedUpdate(event, rowIndex, columnHeader) {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        //#region
        // Ce bloc s'exécute 300ms après la dernière frappe :
        // 1. On récupère la valeur saisie
        // 2. On met à jour le tableau de données
        // 3. On appelle updateCell pour traiter le changement
        const value = event.target.value;
        this.$set(this.rows[rowIndex], this.headers.indexOf(columnHeader), value);
        this.updateCell(rowIndex, columnHeader, value);
        //#endregion
      }, 500);
    },
    //#endregion
  },
};
</script>

<style>
.table-container {
  border: solid 1px whitesmoke;
}
.table-custom {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-custom thead {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-custom th {
  font-weight: 600;
  color: hsl(13, 2%, 55%);
}
td[contenteditable="true"] {
  outline: none;
}
.error-button,
.action-button {
  background: none;
  border: 2px solid transparent;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-button:hover,
.error-button.active {
  border: 2px solid #e74a3b;
}
.action-button-upload:hover {
  border: 2px solid #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
.action-button-refresh:hover {
  border: 2px solid #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}
.action-button-cancel:hover {
  border: 2px solid #dc3545;
  background-color: rgba(220, 53, 69, 0.1);
}
.separator-custom {
  width: 2px;
  height: 100%;
  background-color: #ccc;
}
</style>
