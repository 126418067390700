<template>
  <!-- begin::ReportModal -->
  <div
    class="modal modal-sticky modal-sticky-bottom-right show"
    id="kt_report_modal"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block; padding-left: 0px; overflow: visible"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <!--begin::Card-->
        <div class="card card-custom">
          <!--begin::Header-->
          <div
            class="card-header align-items-center px-4 py-3"
            :class="
              reportModal.data.StatType !== undefined
                ? `bg-${getStatType(reportModal.data.StatType, 'class')} text-white`
                : 'bg-opalean-white opalean-gray-dark'
            "
          >
            <div class="text-left flex-grow-1">
              <!--begin::Dropdown Menu-->
              <i class="flaticon2-download-2 icon-lg text-white ml-2"></i>
              <!--end::Dropdown Menu-->
            </div>
            <div class="text-center flex-grow-1">
              <div class="card-label font-weight-bolder font-size-h5 text-white" v-translate>Get report</div>
              <div class="card-label font-weight-bold font-size-h6 text-white">{{ reportModal.title }}</div>
            </div>
            <div class="text-right flex-grow-1">
              <button type="button" class="btn btn-outline-white btn-sm btn-icon btn-icon-md" @click="dismissModal()">
                <i class="ki ki-close icon-1x"></i>
              </button>
            </div>
          </div>
          <!--end::Header-->

          <!--begin::Body-->

          <div class="card-body align-items-center border-bottom">
            <div class="row">
              <!-- Partner -->
              <div class="form-group col-12 mb-0" v-if="reportModal.data.ReqPartnerID">
                <label class="font-weight-bold" for="report-operation-list-partner"><translate>Partner</translate> <span class="required">*</span></label>
                <Multiselect
                  :id="`report-operation-list-partner`"
                  v-model="Partner"
                  :options="searchedPartners != null ? searchedPartners : Partners"
                  label="Name"
                  track-by="Name"
                  :placeholder="$gettext('Select a partner...')"
                  :internal-search="false"
                  @search-change="ignoreAccents"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <span class="">{{ props.option.Name }}</span> <span class="opacity-50">{{ props.option.City }}</span>
                  </template>
                </Multiselect>
                <b-form-text v-show="false" id="report-operation-list-partner-help" v-translate>Choose a partner to generate the data.</b-form-text>
              </div>

              <!-- Pallet -->
              <div class="form-group col-12 mb-0" v-if="reportModal.data.ReqPalletID">
                <label class="font-weight-bold" for="report-operation-list-pallet"><translate>Pallet</translate> <span class="required">*</span></label>
                <Multiselect
                  :id="`report-operation-list-pallet`"
                  v-model="Pallet"
                  :options="Pallets"
                  label="PalletName"
                  track-by="PalletName"
                  :placeholder="$gettext('Select a pallet...')"
                ></Multiselect>
                <b-form-text v-show="false" id="report-operation-list-pallet-help" v-translate>Choose the pallet to generate the data.</b-form-text>
              </div>

              <!-- Dates -->
              <div class="form-group col-12 mb-0" v-if="reportModal.data.ReqStartDate || reportModal.data.ReqEndDate">
                <label class="font-weight-bold" v-translate for="report-operation-list-datefrom"> Date</label>
                <div class="row">
                  <b-col md="12" class="" v-if="reportModal.data.ReqStartDate">
                    <b-form-group
                      :label="$gettext('From date')"
                      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                      label-for="report-operation-list-fromdate"
                      :description="$gettext('Fill the date ( formatted as DD/MM/YYYY )')"
                    >
                      <b-input-group size="lg" class="mb-3">
                        <b-form-input
                          id="FromDate"
                          class="font-size-h6"
                          :value="FromDate.Date !== '' && FromDate.DateFormatted !== 'No date selected' ? FromDate.DateFormatted : null"
                          type="text"
                          :placeholder="$gettext('Set the starting date')"
                          autocomplete="off"
                          @change="
                            (value) => {
                              onChangeDate(value, 'FromDate');
                            }
                          "
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="FromDate.Date"
                            button-only
                            right
                            dropup
                            aria-controls="FromDate-input"
                            @context="
                              (context) => {
                                onDateContext(context, 'FromDate');
                              }
                            "
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <!--
                        <p class="mb-1">Value: '{{ FromDate.Date }}'</p>
                        <p class="mb-1">Selected: '{{ FromDate.DateSelected }}'</p>
                        <p>Formatted: '{{ FromDate.DateFormatted }}'</p>
                        -->
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="" v-if="reportModal.data.ReqEndDate">
                    <b-form-group
                      :label="$gettext('To date')"
                      label-class="text-dark font-size-xs font-weight-bolder text-uppercase"
                      label-for="report-operation-list-todate"
                      :description="$gettext('Fill the date ( formatted as DD/MM/YYYY )')"
                    >
                      <b-input-group size="lg" class="mb-3">
                        <b-form-input
                          id="ToDate"
                          class="font-size-h6"
                          :value="ToDate.Date !== '' && ToDate.DateFormatted !== 'No date selected' ? ToDate.DateFormatted : null"
                          type="text"
                          :placeholder="$gettext('Set the ending date')"
                          autocomplete="off"
                          @change="
                            (value) => {
                              onChangeDate(value, 'ToDate');
                            }
                          "
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="ToDate.Date"
                            button-only
                            right
                            dropup
                            aria-controls="ToDate-input"
                            @context="
                              (context) => {
                                onDateContext(context, 'ToDate');
                              }
                            "
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <!--
                        <p class="mb-1">Value: '{{ ToDate.Date }}'</p>
                        <p class="mb-1">Selected: '{{ ToDate.DateSelected }}'</p>
                        <p>Formatted: '{{ ToDate.DateFormatted }}'</p>
                        -->
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->

          <!--begin::Footer-->
          <div class="card-footer align-items-center">
            <div class="d-flex align-items-center justify-content-between mt-5">
              <div class="mr-3">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 mr-2"
                  @click="dismissModal()"
                  v-translate
                >
                  Cancel
                </button>
              </div>
              <!-- utiliser pour l'icone fa-file-xls-->
              <link rel="stylesheet" href="https://site-assets.fontawesome.com/releases/v6.5.1/css/all.css" />
              <div>
                <b-button-group class="w-100">
                  <b-button
                    variant="opalean-gray-light"
                    :disabled="isDisabled()"
                    v-if="reportModal.data.AsPDF"
                    :href="getURL(reportModal.data.PDFURL)"
                    target="_blank"
                    v-translate
                    ><i class="fa-solid fa-file-pdf icon-xxl" style="color: #e33931"></i
                  ></b-button>
                  <b-button
                    variant="opalean-gray-light"
                    :disabled="isDisabled()"
                    v-if="reportModal.data.AsCSV"
                    :href="getURL(reportModal.data.CSVURL)"
                    target="_blank"
                    v-translate
                    ><i class="fa-solid fa-file-csv icon-xxl" style="color: #166d66"></i>
                  </b-button>
                  <b-button
                    variant="opalean-gray-light"
                    :disabled="isDisabled()"
                    v-if="reportModal.data.AsXLS"
                    :href="getURL(reportModal.data.XLSURL)"
                    target="_blank"
                    v-translate
                    ><i class="fas fa-file-xls icon-xxl" style="color: #166d66"></i>
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>
          <!--end::Footer-->
        </div>
        <!--end::Card-->
      </div>
    </div>
  </div>
  <!-- end::ReportModal -->
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
var vm = new Vue();

import * as moment from "moment";

import { mapGetters } from "vuex";
import helpers from "@/core/statics/helpers.js";

import Multiselect from "vue-multiselect";

export default {
  name: "ReportModal",
  components: {
    Multiselect,
  },
  props: {
    reportModal: Object,
  },
  data() {
    return {
      //
      accountEmails: [],
      // Form
      Partner: undefined,
      Pallet: undefined,
      FromDate: {
        Date: undefined,
        DateFormatted: "",
        DateSelected: "",
      },
      ToDate: {
        Date: undefined,
        DateFormatted: "",
        DateSelected: "",
      },
      // Search filter Partners
      searchedPartners: null,
    };
  },
  computed: {
    ...mapGetters(["getPartnersFor", "getMorePallets", "getUserEmails"]),
    // Get Partners data
    Partners: function () {
      return this.getPartnersFor("STATS");
    },
    // Get Pallets data
    // Pallets: function () {
    //   return this.getPallets;
    // },
    Pallets: function () {
      /* Returns only ByDefault === false Pallets in order */
      //return this.getMorePallets;
      return _.orderBy(this.getMorePallets, "DisplayOrder") ?? [];
    },
  },
  mounted() {
    // Mount user emails
    this.accountEmails = this.getUserEmails ?? [];
  },
  mixins: [helpers],
  methods: {
    // Modal
    dismissModal() {
      this.reportModal.show = false;
    },
    // Form
    // Datepicker
    onChangeDate(date, direction = "ToDate") {
      // The date from a OperationDateFormatted date or from a French filling
      // https://momentjs.com
      this[direction].Date = moment(String(date), "DD/MM/YYYY").format();
    },
    onDateContext(ctx, direction = "ToDate") {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this[direction].DateFormatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this[direction].DateSelected = ctx.selectedYMD;
    },
    // Export button state
    isDisabled() {
      let disabled = false;
      if (this.reportModal.data.ReqPartnerID === true)
        if (typeof this.Partner !== "undefined") disabled = false;
        else disabled = true;
      if (this.reportModal.data.ReqPalletID === true)
        if (typeof this.Pallet !== "undefined") disabled = false;
        else disabled = true;
      if (this.reportModal.data.ReqStartDate === true)
        if (typeof this.FromDate.Date !== "undefined" && this.FromDate.Date !== "") disabled = false;
        else disabled = true;
      if (this.reportModal.data.ReqEndDate === true)
        if (typeof this.ToDate.Date !== "undefined" && this.ToDate.Date !== "") disabled = false;
        else disabled = true;
      return disabled;
    },
    // Export button URL
    getURL(url) {
      console.log(typeof this.Partner, url);
      let newUrl = url;
      if (typeof this.Partner !== "undefined" && this.Partner != null) newUrl = newUrl.replace("{PartnerID}", this.Partner.PartnerID);
      if (typeof this.Pallet !== "undefined" && this.Pallet != null) newUrl = newUrl.replace("{EmbID}", this.Pallet.PalletID);
      if (typeof this.FromDate.Date !== "undefined" && this.FromDate.Date !== "")
        newUrl = newUrl.replace("{StartDate}", moment(String(this.FromDate.Date)).format("YYYY-MM-DD"));
      if (typeof this.ToDate.Date !== "undefined" && this.ToDate.Date !== "")
        newUrl = newUrl.replace("{EndDate}", moment(String(this.ToDate.Date)).format("YYYY-MM-DD"));
      console.log(newUrl);
      return newUrl;
    },
    // Fix Multiselect search without Diacritics ( accents )
    /* inspired by https://github.com/shentao/vue-multiselect/issues/281#issuecomment-597718858 */
    /* inside map, this refer to Window and not App */
    /* https://stackoverflow.com/questions/46371302/map-unable-to-access-objects-this-function */
    ignoreAccents(search) {
      const needle = _.lowerCase(_.deburr(search));

      console.log(
        "ignoreAccents::",
        search,
        search.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        needle,
        this.Partners.filter(
          (p) =>
            _.lowerCase(_.deburr(p.Name)).includes(needle) ||
            _.lowerCase(_.deburr(p.City)).includes(needle) ||
            _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
            _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
            (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
        )
      );

      this.searchedPartners = search
        ? this.Partners.filter(
            (p) =>
              _.lowerCase(_.deburr(p.Name)).includes(needle) ||
              _.lowerCase(_.deburr(p.City)).includes(needle) ||
              _.lowerCase(_.deburr(p.ZipCode)).includes(needle) ||
              _.lowerCase(_.deburr(p.Reference)).includes(needle) ||
              (typeof p.PartnerRef != "undefined" ? _.lowerCase(_.deburr(p.PartnerRef)).includes(needle) : "")
          )
        : this.Partners;
    },
  },
};
</script>
